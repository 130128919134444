import axiosClient from '../axiosClient';
const PREFIX_URL = "api/hddv/nguoidung";

const userApi = {
    getListUser: (params) => {
        const url = `${PREFIX_URL}`;
        return axiosClient.get(url, { params });
    },
    getUserDetail: (params) => {
        const url = `${PREFIX_URL}/get-user-info`;
        return axiosClient.get(url, { params });
    },
    getUserDetailByCode: (param) => {
        const url = `${PREFIX_URL}/get-user-info/${param}`;
        return axiosClient.get(url, {});
    },
    loginTime: () => {
        const url = `${PREFIX_URL}/dangnhap/new`;
        return axiosClient.post(url, {});
    },
    addUser: (data) => {
        const url = `${PREFIX_URL}/add`;
        return axiosClient.post(url, data);
    },
    editUser: (data, id) => {
        const url = `${PREFIX_URL}/update/${id}`;
        return axiosClient.post(url, data);
    },
    deleteUser: (param) => {
        const url = `${PREFIX_URL}/delete/${param}`;
        return axiosClient.post(url, {});
    },
    // getRoleUser: (params) => {
    //     const url = `${PREFIX_URL}/doanhngieptree`;
    //     return axiosClient.post(url, { params });
    // },
}

export default userApi;
