import { useMutation } from '@tanstack/react-query';
import notificationsUserApi from 'api/notificationsUserApi';

const useNotificationProcessing = () => {
    return useMutation({
        mutationFn: (data) => notificationsUserApi.notificationProcessing(data),
    });
};

export default useNotificationProcessing;
