// import { Server } from 'socket.io-client';
import { io } from 'socket.io-client';
// import { createServer } from "http";
// import { Server } from "socket.io";

// const httpServer = createServer();
const URL = process.env.NODE_ENV === 'production' ? undefined : 'localhost:3001';


// const io = new Server({
//     cors: {
//         origin: "http://localhost:3000"
//     }
// });

// httpServer.listen(3000);

export const socket = io(URL, {
    autoConnect: false,
});
