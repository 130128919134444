import axiosClientNotTimeout from '../axiosClientNotTimeout';
const PREFIX_URL = "api/hddv-xuat-hoa-don";
const PREFIX_URL2 = "api/hddv-dm/nha-cung-cap";
const PREFIX_URL3 = "api/hddv-dm/hang-hoa";

const exportExcelApi = {
    exportFastCA: (params) => {
        const url = `${PREFIX_URL}/xuat-hoa-don-fastca/export-excel-dong-bo-fastca`;
        return axiosClientNotTimeout.get(url, { params });
    },
    exportFastCAAsync: (params) => {
        const url = `${PREFIX_URL}/xuat-hoa-don-fastca/export-excel-bat-dong-bo-fastca`;
        return axiosClientNotTimeout.get(url, { params });
    },
    exportMISA: (params) => {
        const url = `${PREFIX_URL}/xuat-hoa-don-misa/export-excel-dong-bo-misa`;
        return axiosClientNotTimeout.get(url, { params });
    },
    exportSMART: (params) => {
        const url = `${PREFIX_URL}/xuat-hoa-don-smart-pro/export-excel-dong-bo-smart-pro`;
        return axiosClientNotTimeout.get(url, { params });
    },
    exportBANGKE: (params) => {
        const url = `${PREFIX_URL}/input-invoice/export`;
        return axiosClientNotTimeout.get(url, { params });
    },
    exportBANGKEHOADON: (params) => {
        const url = `${PREFIX_URL}/output-invoice/export`;
        return axiosClientNotTimeout.get(url, { params });
    },
    exportBANGKEHANGHOA: (params) => {
        const url = `${PREFIX_URL}/output-invoice-by-goods/export`;
        return axiosClientNotTimeout.get(url, { params });
    },
    exportNCC: (params) => {
        const url = `${PREFIX_URL2}/export-excel`;
        return axiosClientNotTimeout.get(url, { params });
    },
    exportHangHoa: () => {
        const url = `${PREFIX_URL3}/export-excel`;
        return axiosClientNotTimeout.post(url, {});
    },
}

export default exportExcelApi;
