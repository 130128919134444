import { useCallback, useEffect, useMemo, useState } from 'react';
import { Bell } from 'react-feather';
import { Button, Badge, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { showAlert } from 'api/showAlert';
import { notification } from 'antd';
import useGetListNotifications from 'hooks/useGetListNotificationsApi';
import useReadAllNotificationsApi from 'hooks/useReadAllNotificationsApi';
import './NotificationDropdown.scss';
import useNotificationProcessing from 'hooks/useNotificationProcessing';
import NotificationItems from './NotificationItems';
import { NotificationsContext } from './NotificationContext';
import { InfinitySpin } from 'react-loader-spinner';
import { produce } from 'immer';
import { CheckOutlined, DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

const NotificationDropdown = () => {
    const sizeNotifications = {
        size: 5,
        page: 0,
        cb: 0,
    };
    const [isOpen, setIsOpen] = useState(false);
    const [defaultParamsValue, setDefaultParamsValue] = useState(sizeNotifications);
    const [listNotifications, setListNotifications] = useState([]);
    const getListNotifications = useGetListNotifications(defaultParamsValue);
    const readAllNotifications = useReadAllNotificationsApi();
    const notificationProcessing = useNotificationProcessing();
    const location = useLocation();

    const mergeArraysWithoutDuplicates = useCallback((arr1, arr2) => {
        const map = new Map(arr1.map((item) => [item.maThongBao, item]));
        arr2.forEach((item) => map.set(item.maThongBao, item));
        return Array.from(map.values());
    }, []);

    useEffect(() => {
        if (getListNotifications.data && !getListNotifications.isFetching) {
            const { data, status, message, currentPage } = getListNotifications.data;
            if (status === 200) {
                setListNotifications((prevData) => {
                    if (currentPage === 0) {
                        return data;
                    }
                    return mergeArraysWithoutDuplicates(prevData, data);
                });
            } else {
                showAlert('error', notification.FAIL, message);
            }
        }
    }, [getListNotifications.data, mergeArraysWithoutDuplicates, getListNotifications.isFetching]);

    //Click vào quả chuông
    const handleShowNotifications = () => {
        const isClickNotificationsList = document.querySelector('#notificationsList');
        if (!isClickNotificationsList?.classList.contains('show')) {
            setDefaultParamsValue((prev) => {
                return { ...prev, page: 0, cb: prev.cb + 1 };
            });
        }
        toggleShow();
    };

    const toggleShow = useCallback(() => {
        setIsOpen((prevOpen) => !prevOpen);
    }, []);
    const handleClickOutSideNotifications = (event) => {
        const isClickNotificationsList = document.querySelector('#notificationsList');
        if (isClickNotificationsList && isClickNotificationsList.classList.contains('show')) {
            if (!event.target.closest('#notificationsList') && !event.target.closest('#dropdownMenuOptionsAll')) {
                // Clicked outside the container
                setIsOpen(false);
            }
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutSideNotifications);

        return () => {
            document.removeEventListener('click', handleClickOutSideNotifications);
        };
    }, []);

    //Đọc tất cả thông báo
    const handleReadAllNotifications = useCallback(() => {
        readAllNotifications.mutate(undefined, {
            onSuccess: () => {
                setListNotifications((prevData) => {
                    const newData = prevData.map((prevItem) => ({
                        ...prevItem,
                        daDoc: 1,
                    }));
                    return newData;
                });
            },
        });
    }, [readAllNotifications]);

    //Xử lý  1 thông báo
    const handleClickOptionItem = useCallback(
        (item, daDoc = 1) => {
            const obj = { maThongBao: item.maThongBao, daDoc: daDoc };
            notificationProcessing.mutate(obj, {
                onSuccess: (data) => {
                    setListNotifications((prevData) =>
                        produce(prevData, (draftData) => {
                            const index = draftData.findIndex(
                                (prevItem) => prevItem.maThongBao === data.data.maThongBao
                            );
                            if (index !== -1) {
                                draftData[index] = data.data;
                            }
                        })
                    );
                },
            });
        },
        [notificationProcessing]
    );

    const unreadCount = useMemo(
        () => listNotifications.reduce((acc, item) => acc + (item.daDoc === 0 ? 1 : 0), 0),
        [listNotifications]
    );

    const fetchMoreData = () => {
        if (listNotifications.length < getListNotifications.data.totalData) {
            setDefaultParamsValue((prev) => {
                return { ...prev, page: prev.page + 1 };
            });
        }
    };

    const renderLoading = () => {
        return (
            <div className="fallback-spinner">
                <div className="loading bg-light">
                    <InfinitySpin color="#9f1d21" height={55} width={150} />
                </div>
            </div>
        );
    };

    useEffect(() => {
        // Kiểm tra đường dẫn
        if (location.pathname === '/notifications') {
            setIsOpen(false);
        }
    }, [location]);
    return (
        <NotificationsContext.Provider
            value={{
                handleClickOptionItem,
                listNotifications,
                fetchMoreData,
                notificationProcessing,
                getListNotifications,
            }}
        >
            <>
                <UncontrolledDropdown
                    tag="li"
                    id="notificationsList"
                    className="NotificationDropdown dropdown-notification nav-item mr-25"
                    isOpen={isOpen}
                    disabled={location.pathname === '/notifications'}
                >
                    <DropdownToggle tag="a" onClick={handleShowNotifications}>
                        <Bell size={21} />
                        {unreadCount > 0 && (
                            <Badge pill color="danger" className="badge-up">
                                {unreadCount}
                            </Badge>
                        )}
                    </DropdownToggle>
                    <DropdownMenu tag="ul" right className="dropdown-menu-media mt-0">
                        <li className="dropdown-menu-header">
                            <DropdownItem className="d-flex" tag="div" header>
                                <h3 className="notification-title mb-0 mr-auto">Thông báo</h3>
                                <UncontrolledDropdown direction="down" tag="div">
                                    <DropdownToggle tag="div">
                                        <Button.Ripple
                                            className="btn-icon rounded-circle"
                                            size="sm"
                                            color="flat-secondary"
                                        >
                                            <EllipsisOutlined />
                                        </Button.Ripple>
                                    </DropdownToggle>
                                    <DropdownMenu
                                        tag="ul"
                                        id="dropdownMenuOptionsAll"
                                        className="dropdownMenuOptions"
                                        right={false}
                                    >
                                        <li>
                                            <DropdownItem className="w--100" onClick={handleReadAllNotifications}>
                                                <CheckOutlined className="mr-50" />
                                                Đánh dấu đã đọc tất cá
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem className="w--100">
                                                <DeleteOutlined className="mr-50" />
                                                Gỡ tất cả
                                            </DropdownItem>
                                        </li>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </DropdownItem>
                        </li>

                        {notificationProcessing.isLoading && renderLoading()}
                        <li id="parentScrollDiv" className="media-list scrollable-container overflow-auto">
                            <NotificationItems />
                        </li>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </>
        </NotificationsContext.Provider>
    );
};

export default NotificationDropdown;
