import { useEffect } from 'react';
import ChangeUserIframe from './ChangeUserIframe';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ChangeUserIframeDialog = (props) => {
    const { visible, onHideDialog } = props;

    const handleModal = () => {
        onHideDialog();
    };

    const handleEvent = (event) => {
        event.data === 'success-change-phone' && handleModal();
        event.data === 'success-change-email' && handleModal();
    };

    useEffect(() => {
        window.addEventListener('message', handleEvent);
        return () => window.addEventListener('message', handleEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            isOpen={visible}
            open={visible}
            onHideDialog={onHideDialog}
            className="modal-dialog-centered modal-lg max-wr--80"
        >
            <ModalHeader toggle={() => onHideDialog()}>
                <div className="d-flex justify-content-between">
                    <div>Thay đổi tài khoản</div>
                </div>
            </ModalHeader>
            <ModalBody className="cs-nav-tabs ">
                <ChangeUserIframe {...props} className="min-vh--47"/>
            </ModalBody>
        </Modal>
    );
};

export default ChangeUserIframeDialog;
