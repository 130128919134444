import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import RegisterFCMTokenApi from '../../api/RegisterFCMTokenApi';
import { showAlert } from '../../api/showAlert';
import { useState } from 'react';

// var firebaseConfig = {
//     apiKey: "YOUR_API_KEY",
//     authDomain: "YOUR_AUTH_DOMAIN",
//     projectId: "YOUR_PROJECT_ID",
//     storageBucket: "YOUR_STORAGE_BUCKET",
//     messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
//     appId: "YOUR_APP_ID"
// };
var firebaseConfig = {
    apiKey: "AIzaSyCZGCJU6fcWCnRfbWqDIZ7MUgwlQO3hAmc",
    authDomain: "cyberbill-hkd-web.firebaseapp.com",
    projectId: "cyberbill-hkd-web",
    storageBucket: "cyberbill-hkd-web.appspot.com",
    messagingSenderId: "12383177971",
    appId: "1:12383177971:web:00a5044afc0c545db9fb86",
    measurementId: "G-BLRRFG0F0S"
};
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();
const vapidKey = "BAl6eSLcj2G8nVwtMbO_WMy2gW07PU6FlX1H0BXxRC3dtcY9O8TDnmx57Qn6OjwF_GTE_yLFplkEBVsdz6XY0G0"
export const GetToken = (setTokenFound) => {
    const [isToken, setIsToken] = useState(false);
    return messaging.getToken({ vapidKey: vapidKey }).then(async (currentToken) => {
        if (currentToken) {
            if (!isToken) {
                try {
                    const result = await RegisterFCMTokenApi.register({
                        "fcm_device_token": currentToken
                    });
                    if (result) {
                        const { status_code, error, records } = result;
                        if (status_code === 200) {
                            setIsToken(true);
                        }
                        if (status_code === 401) {
                            showAlert("error", "Đăng nhập thất bại!", error.message);
                        }
                    }
                } catch (error) {
                    showAlert("error", "Có lỗi xảy ra!");
                };
            }
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
