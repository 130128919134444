// ** Dropdowns Imports
import { Fragment } from "react";
import * as Icon from "react-feather";
// ** Third Party Components
import { CrownOutlined, RetweetOutlined } from '@ant-design/icons';
import { Badge, Button as ButtonAntd, Divider, Progress, Tag, Tooltip, Typography } from 'antd';
import { Bell, Menu, Moon, Sun } from "react-feather";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  DropdownMenu, DropdownToggle, NavItem,
  NavLink, UncontrolledDropdown
} from "reactstrap";
import UserDropdown from "./UserDropdown";
import NotificationDropdown from "./NotificationDropdown";

const { Text } = Typography;
const NavbarUser = (props) => {
  // ** Props
  const store = useSelector(state => state)
  const companyInfoStore = store.authHddv?.accountData?.hddvDoanhNghiep
  const packageInfo = store.authHddv?.accountData?.dsGoiDichVu
  const packageUse = store.authHddv?.accountData?.goiDichVuSuDung
  const { skin, setSkin, setMenuVisibility } = props;
  const history = useHistory();
  const amountInvoiceUsed = packageInfo?.reduce((acc, item) => {
    acc += item.soLuongHoaDonSuDung;
    return acc
  }, 0)
  const amountInvoiceRemain = packageInfo?.reduce((acc, item) => {
    acc += item.soLuongHoaDonConLai;
    return acc
  }, 0)
  const amountInvoiceTotal = packageInfo?.reduce((acc, item) => {
    acc += item.soLuongHoaDon;
    return acc
  }, 0)
  const percentInvoice = Math.round(amountInvoiceUsed / amountInvoiceTotal * 100)

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return <Sun className="ficon" onClick={() => setSkin("light")} />;
    } else {
      return <Moon className="ficon" onClick={() => setSkin("dark")} />;
    }
  };



  return (
    <Fragment>
      <ul className="navbar-nav d-xl-none d-flex align-items-center">
        <NavItem className="mobile-menu mr-auto">
          <NavLink
            className="nav-menu-main menu-toggle hidden-xs is-active"
            onClick={() => setMenuVisibility(true)}
          >
            <Menu className="ficon" />
          </NavLink>
        </NavItem>
      </ul>
      {/* <div
        className="bookmark-wrapper d-flex align-items-center"
        style={{ width: "37%" }}
      > */}
      <ul className="nav navbar-nav align-items-center">
        {/* <NavItem className="d-none d-lg-block">
          <NavLink className="nav-link-style">
            Công ty cổ phần CyberLotus
          </NavLink>
        </NavItem> */}
        <div className="pr-2 d-md-flex d-sm-none d-none">
          <div className="d-flex flex-column mr-1">
            <div className="d-flex flex-row align-items-center max-wr-lg-20 max-wr-md-15 flex-wrap">
              <div className="font-weight-bolder text-primary">
                <Text
                  ellipsis={{ tooltip: companyInfoStore?.tenDoanhNghiep }}
                  className="max-wr-lg-20 max-wr-md-10"
                >
                  {(companyInfoStore && companyInfoStore?.tenDoanhNghiep)}
                </Text></div>
              <Divider type="vertical" />
              <div className="font-size-12 d-flex text-primary" style={{ lineHeight: 1 }}>
                {(companyInfoStore && `MST: ${companyInfoStore?.mstDoanhNghiep}`)}
              </div>
            </div>
            <div className="font-size-12">
              <i>Email nhận hóa đơn: <a href={`mailto:${companyInfoStore?.emailNhanHd}`} className="text-info text-decorate">
                <u>
                  {(companyInfoStore && companyInfoStore?.emailNhanHd)}
                </u>
              </a></i>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Tooltip title="Thay đổi doanh nghiệp">
              {/* <SwapOutlined /> */}
              <ButtonAntd onClick={() => history.push("/company-list")} size={"small"} shape="circle" icon={<RetweetOutlined />} />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex flex-wrap max-wr-xl-10 max-wr-md-10">
          <Tag icon={<CrownOutlined />} color="#cd201f"><span>{packageUse?.maGoi}</span></Tag>
          {/* <Progress
            type="circle"
            trailColor="#e6f4ff"
            percent={60}
            strokeWidth={20}
            size={14}
            format={(number) => `Gói sử dụng còn 100 ${number} hóa đơn`}
          /> */}
          <div className="w--100">
            <Tooltip title={`Gói sử dụng còn ${amountInvoiceRemain ? amountInvoiceRemain : 0} hóa đơn`}>
              <Progress
                status="active"
                strokeColor={{
                  '0%': '#9f1d21',
                  '100%': '#ED4C51',
                }}
                className="max-wr-5 mb-0"
                percent={amountInvoiceRemain ? percentInvoice : 100}
                size="small"
                format={(number) => <span className="font-size-12 text-info">{number}%</span>}
              />
            </Tooltip>
          </div>
          {/* <Text italic>(Gói sử dụng còn 100 hóa đơn)</Text> */}
        </div>
      </ul>
      {/* <div className="w-100">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Search width="18px" height="18px" />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Tìm kiếm" />
          </InputGroup>
        </div> */}
      {/* </div> */}
      <ul className="nav navbar-nav align-items-center ml-auto">
        {/* <div className="mr-2 border-right pr-2 d-md-flex d-sm-none d-none">
          <div className="d-flex flex-column mr-1">
            <div className="text-danger">
              Điểm thưởng: <span className="font-weight-bold">9999{"  "}</span>
            </div>
            <div className="text-danger">
              Số dư: <span className="font-weight-bold">{money}</span>
            </div>
          </div>
          <Button.Ripple color="primary" onClick={handleDeposit}>
            Nạp tiền
          </Button.Ripple>
        </div> */}
        {/* <IntlDropdown /> */}
        {/* <NavItem className="nav-search" onClick={() => setNavbarSearch(!navbarSearch)}>
          <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search" />
          </NavLink>
          <div
            className={classnames("search-input", {
              open: navbarSearch,
              "d-none": navbarSearch === false,
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary" />
              <span>Tra cứu hóa đơn</span>
            </div>
            <Autocomplete
              className="form-control"
              suggestions={suggestion}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="Tìm kiếm..."
              autoFocus={true}
              handleNavbarSearch={handleNavbarSearch}
              clearInput={navbarSearch}
              externalClick={(e) => {
                setNavbarSearch(false);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  setNavbarSearch(false);
                  // props.handleAppOverlay("");
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : "X"];
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion,
                    })}
                    key={i}
                    onClick={(e) => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        "d-flex justify-content-between align-items-center":
                          item.file || item.img,
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={17} />
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                  ? item.email
                                  : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                                ? item.date
                                : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                );
              }}
              onSuggestionsShown={(userInput) => {
                if (navbarSearch) {
                  // props.handleAppOverlay(userInput);
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={(e) => {
                  e.stopPropagation();
                  setNavbarSearch(false);
                  // props.handleAppOverlay("");
                }}
              />
            </div>
          </div>
        </NavItem> */}
        {/* <NavItem className="">
          <NavLink>
            <Icon.Search size={17} className="primary mr-50" />
            <span className="text-bold-500">Tra cứu hóa đơn</span>
          </NavLink>
        </NavItem> */}
        <NavItem className="">
          <NavLink>
            <Icon.Phone size={17} className="text-primary mr-50" />
            <span className="text-bold-500 text-primary">1900.2038</span>
          </NavLink>
        </NavItem>
        <NavItem className="d-none d-lg-block">
          <NavLink className="nav-link-style">
            <ThemeToggler />
          </NavLink>
        </NavItem>
        <NotificationDropdown />
        <UserDropdown />
      </ul>
    </Fragment>
  );
};
export default NavbarUser;
