/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
import { routerMain } from "constants/routerMain";

export default [
  {
    id: "system_config",
    title: "Cấu hình hệ thống",
    type: "item",
    root: true,
    footer: true,
    icon: <i className="fal fa-cog"></i>,
    navLink: `${routerMain.CONFIG}${routerMain.AGENCY}`,
    top_footer: true,
  },
  {
    id: "support",
    title: "Hỗ trợ",
    type: "item",
    root: true,
    footer: true,
    icon: <i className="fal fa-headset"></i>,
    navLink: "/err",
  },
]

