import React from 'react';

const IntegratedIframe = ({ src, width, height, className }) => {
    return (
        <iframe
            src={src}
            height={height ?? '100%'}
            title="viewPdf"
            width={width ?? '100%'}
            loading="lazy"
            frameBorder="0"
            allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
            allowFullScreen
            className={className || ''}
        ></iframe>
    );
};

export default IntegratedIframe;
