import { routerMain } from 'constants/routerMain';
import { role } from 'constants/role';
// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 'home',
        title: 'Trang chủ',
        type: 'item',
        root: true,
        icon: <i className="fal fa-desktop"></i>,
        navLink: routerMain.HOME,
        top: true,
        permissions: role.trangchu,
    },
    {
        id: 'invoice',
        title: 'Hóa đơn đầu vào',
        type: 'item',
        root: true,
        icon: <i className="fal fa-file-alt"></i>,
        badge: 'primary',
        // permissions: role.hddv,
        // badgeText: "5",
        children: [
            {
                id: 'list_invoice',
                title: 'Danh sách hóa đơn',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                navLink: routerMain.LIST_INVOICE,
                permissions: role.dshoadon,
            },
            {
                id: 'create_invoice',
                title: 'Lập mới hóa đơn',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                navLink: routerMain.CREATE_INVOICE,
                permissions: role.taohoadon,
            },
        ],
    },
    {
        id: 'report',
        title: 'Công ty - Chi nhánh',
        type: 'item',
        root: true,
        icon: <i className="fal fa-building"></i>,
        badge: 'primary',
        // permissions: role.qlct,
        // badgeText: "3",
        children: [
            {
                id: 'company-list',
                title: 'Danh sách công ty',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                navLink: routerMain.LIST_COMPANY,
                permissions: role.dscongty,
            },
        ],
    },
    {
        id: 'connecttct',
        title: 'Kết nối tổng cục thuế',
        type: 'item',
        root: true,
        icon: <i className="fal fa-globe"></i>,
        badge: 'primary',
        navLink: routerMain.CONNECT_TCT,
        permissions: role.ketnoitct,
    },
    {
        id: 'mailbox',
        title: 'Hộp thư',
        type: 'item',
        root: true,
        icon: <i className="fal fa-envelope"></i>,
        badge: 'primary',
        navLink: routerMain.MAILBOX,
        permissions: role.hopthu,
    },
    // {
    //   id: "contract",
    //   title: "Quản lý hợp đồng",
    //   type: "item",
    //   root: true,
    //   icon: <i className="fal fa-file-contract"></i>,
    //   badge: "primary",
    //   navLink: routerMain.CONTRACT,
    //   permissions: role.qlhopdong,
    // },
    {
        id: 'management',
        title: 'Quản lý người dùng',
        type: 'item',
        root: true,
        icon: <i className="fal fa-users"></i>,
        badge: 'primary',
        // permissions: role.qlnguoidung,
        children: [
            {
                id: 'management',
                title: 'Danh sách người dùng',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                navLink: routerMain.USER_LIST,
                permissions: role.dsnguoidung,
            },
            {
                id: 'config-role',
                title: 'Phân quyền',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                navLink: routerMain.ROLE,
                permissions: role.phanquyen,
            },
        ],
    },
    {
        id: 'category',
        title: 'Danh mục',
        type: 'item',
        root: true,
        icon: <i className="fal fa-book-open"></i>,
        badge: 'primary',
        // permissions: role.danhmuc,
        // badgeText: "3",
        children: [
            {
                id: 'supplier',
                title: 'Danh mục nhà cung cấp',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                navLink: routerMain.SUPPLIER,
                permissions: role.dsncc,
            },
            {
                id: 'product',
                title: 'Danh mục hàng hóa',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                navLink: routerMain.PRODUCT,
                permissions: role.dshanghoa,
            },
        ],
    },
    {
        id: 'config',
        title: 'Quản lý cấu hình',
        type: 'item',
        root: true,
        icon: <i className="fal fa-gear"></i>,
        badge: 'primary',
        // permissions: role.cauhinh,
        // badgeText: "3",
        children: [
            {
                id: 'config-system',
                title: 'Cấu hình hệ thống',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                permissions: role.cauhinhhethong,
                navLink: routerMain.CONFIG_SYSTEM,
            },
            {
                id: 'email-template',
                title: 'Quản lý mẫu email',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                navLink: routerMain.CONFIG_EMAIL,
                permissions: role.quanlymauemail,
            },
        ],
    },
    {
        id: 'helpdesk',
        title: 'Hỗ trợ',
        type: 'item',
        root: true,
        icon: <i className="fal fa-headset"></i>,
        badge: 'primary',
        // permissions: role.qlct,
        // badgeText: "3",
        children: [
            // {
            //     id: 'instructions-for-use',
            //     title: 'Hướng dẫn sử dụng',
            //     type: 'item',
            //     icon: <i className="fal fa-circle"></i>,
            //     navLink: routerMain.INSTRUCTIONS_FOR_USE,
            //     //permissions: role.huongdansudung,
            // },
            {
                id: 'request-support',
                title: 'Yêu cầu hỗ trợ',
                type: 'item',
                icon: <i className="fal fa-circle"></i>,
                navLink: routerMain.REQUEST_SUPPORT,
                //permissions: role.yeucauhotro,
            },
        ],
    },
];

const roleData = [
    'Sửa công ty',
    'Xóa mẫu email',
    'Duyệt hàng loạt\n',
    'Dán nhãn',
    'Tạo hóa đơn',
    'Tải xuống PDF',
    'Danh sách hóa đơn không duyệt',
    'Trang chủ',
    'Tải xuống XML',
    'Danh sách hóa đơn',
    'Thêm công ty',
    'Import hàng hóa',
    'Chỉnh sửa hóa đơn',
    'Sửa người dùng',
    'Danh sách hóa đơn đã duyệt',
    'Quản lý hợp đồng',
    'Xem danh sách hộp thư',
    'Nhập liệu hóa đơn (Nhập liệu thủ công)',
    'Danh sách hóa đơn chờ xử lý',
    'Khóa/Mở khóa người dùng',
    'Xem danh sách người dùng',
    'Kết nối với TCT',
    'Cấu hình hiển thị',
    'Không duyệt hóa đơn',
    'Xóa hàng hóa',
    'Import hóa dơn',
    'Xem danh sách công ty',
    'Danh sách hóa đơn đã hủy',
    'Xem lịch sử kết nối với TCT',
    'Xuất hóa đơn',
    'Hộp thư',
    'Hủy hóa đơn',
    'Đồng bộ từ TCT',
    'Duyệt hóa đơn',
    'Xem chi tiết hóa đơn',
    'Danh mục',
    'Danh sách tất cả hóa đơn',
    'Xem chi tiết kết nối với TCT',
    'Hóa đơn đầu vào',
    'Quản lý người dùng',
    'Lọc hóa đơn',
    'Lịch sử hoạt động',
    'Tạo người dùng',
    'Xóa công ty',
    'Đồng bộ ngay',
    'Kết nối TCT',
    'Xem chi tiết hộp thư',
    'Kết nối lại',
    'Quản lý công ty',
    'Duyệt hóa đơn',
    'Cấu hình',
    'Quản lý mẫu email',
    'Thông tin hóa đơn',
    'Ngắt kết nối ',
    'In hóa đơn',
    'Xóa hóa đơn',
];
