import IntegratedIframe from '@core/components/iframe/IntegratedIframe';
import { useEffect } from 'react';

const ChangeUserIframe = (props) => {
    const { url, onHideDialog,  width, height, className } = props;


    const handleModal = () => {
        onHideDialog && onHideDialog();
    };

    const handleEvent = (event) => {
        event.data === 'success-change-phone' && handleModal();
        event.data === 'success-change-email' && handleModal();
    };

    useEffect(() => {
        window.addEventListener('message', handleEvent);
        return () => window.addEventListener('message', handleEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <IntegratedIframe src={url} width={width} height={height} className={className}/>;
};

export default ChangeUserIframe;
