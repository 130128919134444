import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    accountData: null,
    roleData: null,
    isAuth: false,
    authData: null,
};

export const authSlice = createSlice({
    name: 'authHddv',
    initialState,
    reducers: {
        setAccountData: (state, action) => {
            state.accountData = action.payload;
        },
        setRoleData: (state, action) => {
            state.roleData = action.payload;
        },
        unsetAccountData: (state) => {
            state.accountData = null;
        },
        setAuthData: (state, action) => {
            state.authData = action.payload;
        },
        unSetAuthData: (state, action) => {
            state.authData = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setAccountData, setRoleData, unsetAccountData, setAuthData, unSetAuthData } = authSlice.actions;

export const selectAccount = (state) => state?.authHddv?.accountData;
export const selectAuth = (state) => state?.authHddv;
export const selectAuthData = (state) => state?.authHddv?.authData;
export const selectRoleData = (state) => state?.authHddv?.roleData;

export default authSlice.reducer;
