
const oidcConfig = {
    client_id: process.env.REACT_APP_KC_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_BASE_OIDC_URL_CALLBACK,
    // responseType: 'code',
    post_logout_redirect_uri: process.env.REACT_APP_BASE_OIDC_URL_PRELOGOUT,
    // scope: "openid profile email roles",
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    silent_redirect_uri: process.env.REACT_APP_OIDC_SILENT_REDIRECT_URI,
    // automaticSilentRenew: true,
    // loadUserInfo: true,
    // userStore: new WebStorageStateStore({ store: window.localStorage }),
    monitorSession: true,
    monitorAnonymousSession: true,
    onSigninCallback() {
        // You must provide an implementation of onSigninCallback to oidcConfig to remove the payload
        // from the URL upon successful login.
        // Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.

        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        );
    },
}
export const oidcConfigVerAxa = {
    client_id: process.env.REACT_APP_KC_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_BASE_OIDC_URL_CALLBACK,
    response_type: "code",
    post_logout_redirect_uri: process.env.REACT_APP_BASE_OIDC_URL_PRELOGOUT,
    scope: "openid profile email roles",
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    silent_redirect_uri: process.env.REACT_APP_OIDC_SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    loadUserInfo: true
}


export default oidcConfig