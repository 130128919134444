// ** React Imports
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

// ** Third Party Components
import { Disc, X, Circle } from 'react-feather'

// ** Config
import themeConfig from '@configs/themeConfig'
import { useSkin } from '@hooks/useSkin';
import { useSelector } from 'react-redux';

const VerticalMenuHeader = props => {
  // ** Props
  const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover } = props

  const skin = useSelector(state => state.layout.skin)
  const [logo, setLogo] = useState(themeConfig.app.appLogoImage)
  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])
  useEffect(() => {
    if (skin === 'dark') {
      setLogo(themeConfig.app.appLogoImageDark)
    } else {
      setLogo(themeConfig.app.appLogoImage)
    }
  }, [skin])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour='toggle-icon'
          className='toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour='toggle-icon'
          className='toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (
    <div className='navbar-header'>
      <ul className='nav navbar-nav flex-row'>
        <li className='nav-item mr-auto d-flex'>
          <NavLink to='/home' className='navbar-brand'>
            <span className='brand-logo'>
              {(!menuCollapsed || menuHover) ?
                <img src={logo} alt='logo' /> :
                <img src={themeConfig.app.appLogoImagemini} style={{ width: 36, marginTop: "1rem" }} alt='logomini' />}
            </span>
            {/* <h2 className='brand-text mb-0'>{themeConfig.app.appName}</h2> */}
          </NavLink>
        </li>
        <li className='nav-item nav-toggle'>
          <div className='nav-link modern-nav-toggle cursor-pointer'>
            <Toggler />
            <X onClick={() => setMenuVisibility(false)} className='toggle-icon icon-x d-block d-xl-none' size={20} />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
