import { routerMain } from 'constants/routerMain';
import { role } from 'constants/role';
import { lazy } from 'react';

// ** Document title
const TemplateTitle = 'xBillStore';

// ** Default Route
const DefaultRoute = routerMain.HOME;

// ** Merge Routes
const Routes = [
    {
        path: routerMain.UNREGISTER,
        exact: true,
        component: lazy(() => import('../../views/Unregisted')),
        layout: 'BlankLayout',
    },
    {
        path: routerMain.ANNUAL_FEES,
        exact: true,
        component: lazy(() => import('../../views/AnnualFees')),
        layout: 'BlankLayout',
    },
    {
        path: routerMain.HOME,
        exact: true,
        component: lazy(() => import('../../views/dashboard/analytics-main/AnalyticsDashboard')),
        permissions: role.trangchu,
    },
    {
        path: routerMain.LIST_COMPANY,
        exact: true,
        component: lazy(() => import('../../views/company-department/CompanyList')),
        permissions: role.qlct,
    },
    {
        path: routerMain.USER_LIST,
        exact: true,
        component: lazy(() => import('../../views/user-list/UserList')),
        permissions: role.qlnguoidung,
    },
    {
        path: routerMain.CONNECT_TCT,
        exact: true,
        component: lazy(() => import('../../views/connect-tct/ConnectTCT')),
        permissions: role.ketnoitct,
    },
    {
        path: routerMain.SUPPLIER_INVOICE_ADD,
        exact: true,
        component: lazy(() => import('../../views/supplier-invoices/supplier-invoices-create/SupplierInvoiceCreate')),
        permissions: role.taohoadon,
    },
    {
        path: routerMain.ADD_INPUT,
        exact: true,
        component: lazy(() => import('../../views/supplier-invoices/supplier-invoices-create/add/AddInput')),
        permissions: role.nhaplieuhoadon,
    },
    {
        path: routerMain.ADD_INPUT_UUID,
        exact: true,
        component: lazy(() => import('../../views/supplier-invoices/supplier-invoices-create/add/AddInput')),
        permissions: role.nhaplieuhoadon,
    },
    {
        path: routerMain.ADD_INPUT_EDIT_UUID,
        exact: true,
        component: lazy(() => import('../../views/supplier-invoices/supplier-invoices-create/add/AddInput')),
        permissions: role.suahoadon,
    },
    {
        path: routerMain.ADD_INPUT_VIEW_UUID,
        exact: true,
        component: lazy(() => import('../../views/supplier-invoices/supplier-invoices-view/add/AddInput')),
        permissions: role.cthoadon,
    },
    {
        path: routerMain.SUPPLIER,
        exact: true,
        component: lazy(() => import('../../views/category/category-supplier/CategorySupplierList')),
        permissions: role.nhacungcap,
    },
    {
        path: routerMain.PRODUCT,
        exact: true,
        component: lazy(() => import('../../views/category/category-product/ProductList')),
        permissions: role.hanghoa,
    },
    // {
    //   path: routerMain.ACCOUNT,
    //   component: lazy(() =>
    //     import("../../views/account-settings/AccountSettings")
    //   ),
    // },
    {
        path: routerMain.ERROR,
        component: lazy(() => import('../../views/Error')),
        layout: 'BlankLayout',
    },
    {
        path: `${routerMain.CONFIG}/system`,
        component: lazy(() => import('../../views/config-system/ConfigInvoice')),
        permissions: role.cauhinhhethong,
    },
    {
        path: `${routerMain.CONFIG}/role`,
        component: lazy(() => import('../../views/config-system/config-role/RoleList')),
        permissions: role.phanquyen,
    },
    {
        path: `${routerMain.CONFIG}/email-template`,
        component: lazy(() => import('../../views/config-system/config-email-template/EmailTemplateList')),
        permissions: role.quanlymauemail,
    },
    {
        path: routerMain.LIST_INVOICE,
        component: lazy(() => import('../../views/supplier-invoices/InvoiceList')),
        permissions: role.dshoadon,
    },
    {
        path: routerMain.LIST_PACKAGE,
        component: lazy(() => import('../../views/package-service/PackageList')),
        // permissions: role.hanghoa,
    },

    {
        path: routerMain.MAILBOX,
        exact: true,
        component: lazy(() => import('../../views/mailbox/MailBoxList')),
        permissions: role.hopthu,
    },
    {
        path: routerMain.CONTRACT,
        component: lazy(() => import('../../views/contract/ContractList')),
    },
    {
        path: routerMain.INSTRUCTIONS_FOR_USE,
        component: lazy(() => import('../../views/helpdesk/InstructionsForUse')),
    },
    {
        path: routerMain.REQUEST_SUPPORT,
        component: lazy(() => import('../../views/helpdesk/request-support/RequestSupport')),
    },
    {
        path: routerMain.NOTIFICATIONS,
        component: lazy(() => import('../../views/notifications/NotificationsPage')),
    },
];

export { DefaultRoute, TemplateTitle, Routes };
