// ** React Imports
import { useState } from 'react';
import { Link } from 'react-router-dom';

// ** Custom Components
import Avatar from '@components/avatar';

// ** Utils

// ** Store & Actions
// import { handleLogout } from '@store/actions/auth'

// ** Third Party Components
import { Mail, Power, User, RefreshCcw } from 'react-feather';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/portrait/small/avatardefault.svg';
import { Card, Modal } from 'antd';
import { routerMain } from 'constants/routerMain';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import ChangeUserIframeDialog from './ChangeUserIframeDialog';
import { urlApi } from 'constants/urlApi';

const UserDropdown = () => {
    // ** Store Vars
    const store = useSelector((state) => state);
    const auth = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const companyInfoStore = store.authHddv.accountData;
    // ** State
    const handleLogout = () => {
        auth.removeUser();
        auth.signoutRedirect();
        localStorage.clear();
    };

    const handleNavigation = (e, path) => {
        e.preventDefault();
        window.location.replace(path);
    };
    const handleOpenPackage = () => {
        setIsModalOpen(!isModalOpen);
    };
    // Confirm logout
    const handleConfirmText = (e) => {
        e.preventDefault();
        handleLogout();
    };

    //** ComponentDidMount

    //** Vars
    const userAvatar = (companyInfoStore && companyInfoStore?.user?.avatar_base64) || defaultAvatar;

    const [isIframe, setIsIframe] = useState(false);
    const [urlIframe, setUrlIframe] = useState('');

    const handelShowModal = () => {
        const baseUrl = `${process.env.REACT_APP_DOMAIN_CYBERID}${urlApi.CHANGE_PROFILE_CYBERID}/${process.env.REACT_APP_APP_ID}`;
        setUrlIframe(baseUrl);
        setIsIframe(!isIframe);
    };

    return (
        <>
            <UncontrolledDropdown direction="up" tag="li" className="dropdown-user nav-item">
                <DropdownToggle
                    href="/"
                    tag="a"
                    className="nav-link dropdown-user-link"
                    onClick={(e) => e.preventDefault()}
                >
                    <div className="user-nav d-sm-flex d-none">
                        <span className="user-name font-weight-bold">
                            {(companyInfoStore && companyInfoStore?.hddvNguoiDung?.hoTen) || ''}
                        </span>
                    </div>
                    <Avatar img={userAvatar} imgHeight="40" imgWidth="40" status="online" />
                </DropdownToggle>
                <DropdownMenu right={false}>
                    <DropdownItem
                        tag={Link}
                        to="/account"
                        onClick={(e) => handleNavigation(e, process.env.REACT_APP_KC_PERSONAL_USER_LINK)}
                    >
                        <User size={14} className="mr-75" />
                        <span className="align-middle">Hồ sơ cá nhân</span>
                    </DropdownItem>
                    <DropdownItem onClick={handelShowModal}>
                        <RefreshCcw size={14} className="mr-75" />
                        <span className="align-middle">Thay đổi tài khoản</span>
                    </DropdownItem>
                    {/* <DropdownItem tag={Link} to='#' onClick={e => handleNavigationOrg(e, process.env.REACT_APP_KC_ORG_LINK)}>
          <Mail size={14} className='mr-75' />
          <span className='align-middle'>Hồ sơ doanh nghiệp</span>
        </DropdownItem> */}
                    <DropdownItem
                        tag={Link}
                        to="#"
                        onClick={(e) => handleNavigation(e, routerMain.LIST_PACKAGE)}
                        // onClick={handleOpenPackage}
                    >
                        <Mail size={14} className="mr-75" />
                        <span className="align-middle">Quản lý gói dịch vụ</span>
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/login" onClick={handleConfirmText}>
                        <Power size={14} className="mr-75" />
                        <span className="align-middle">Đăng xuất</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <Modal
                title="Danh sách gói dịch vụ"
                open={isModalOpen}
                onCancel={handleOpenPackage}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        {/* <Button>Custom Button</Button>
          <CancelBtn />
          <OkBtn /> */}
                    </>
                )}
                width={'80%'}
                height="auto"
            >
                <Card className="mx-0 my-0">
                    <div className={'embed-responsive'} style={{ height: '75vh' }}>
                        <iframe
                            width="100%"
                            // height="auto"
                            src={
                                'https://billdev.etrans.mycyber.vn/iframe/goi-san-pham/7B7F0244576B4B7EB1E41EC200B030B8'
                            }
                            frameBorder="0"
                            allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
                            allowFullScreen
                            // title={isInvite ? intl.formatMessage({ id: "User.moinguoidung" }) : intl.formatMessage({ id: "User.themnguoidung" })}
                        />
                    </div>
                </Card>
            </Modal>
            {isIframe && <ChangeUserIframeDialog visible={isIframe} onHideDialog={handelShowModal} url={urlIframe} />}
        </>
    );
};

export default UserDropdown;
