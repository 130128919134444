import axiosClient from './axiosClient';

const PREFIX_URL = 'api/hddv/thongbao';
const notificationsUserApi = {
    getListNotifications: (params) => {
        const url = `${PREFIX_URL}/list-by-user-code`;
        return axiosClient.get(url, { params });
    },
    deleteNotificationById: (id) => {
        const url = `${PREFIX_URL}/${id}`;
        return axiosClient.put(url);
    },
    readAllNotifications: () => {
        const url = `${PREFIX_URL}/update-reading-status/all`;
        return axiosClient.put(url);
    },
    notificationProcessing: (data) => {
        const url = `${PREFIX_URL}/update-reading-status`;
        return axiosClient.post(url, data);
    },
};

export default notificationsUserApi;
