export enum role {
    trangchu = 'trang-chu',
    hddv = 'hoa-don-dau-vao',
    qlct = 'quan-ly-cong-ty',
    ketnoitct = 'ket-noi-tct',
    hopthu = 'hop-thu',
    qlnguoidung = 'quan-ly-nguoi-dung',
    qlhopdong = 'quan-ly-hop-dong',
    danhmuc = 'danh-muc',
    cauhinh = 'cau-hinh',
    dshoadon = 'ds-hoa-don',
    taohoadon = 'tao-hoa-don',
    suahoadon = 'sua-hoa-don',
    xoahoadon = 'xoa-hoa-don',
    duyethoadon = 'duyet-hoa-don',
    cthoadon = 'ct-hoa-don',
    xuathoadon = 'xuat-hoa-don',
    dscongty = 'ds-cong-ty',
    themcongty = 'them-cong-ty',
    suacongty = 'sua-cong-ty',
    xoacongty = 'xoa-cong-ty',
    batketnoitct = 'bat-ket-noi-tct',
    ngatketnoitct = 'ngat-ket-noi-tct',
    dongbongay = 'dong-bo-ngay',
    ketnoilai = 'ket-noi-lai',
    lsketnoitct = 'ls-ket-noi-tct',
    ctketnoitct = 'ct-ket-noi-tct',
    dshopthu = 'ds-hop-thu',
    cthopthu = 'ct-hop-thu',
    dshopdong = 'ds-hop-dong',
    cthopdong = 'ct-hop-dong',
    kyhopdong = 'ky-hop-dong',
    dsnguoidung = 'ds-nguoi-dung',
    taonguoidung = 'tao-nguoi-dung',
    suanguoidung = 'sua-nguoi-dung',
    khoamonguoidung = 'khoa-mo-nguoi-dung',
    nhacungcap = 'nha-cung-cap',
    hanghoa = 'hang-hoa',
    phanquyen = 'phan-quyen',
    quanlymauemail = 'quan-ly-mau-email',
    lsguiemail = 'ls-gui-email',
    cauhinhhethong = 'cau-hinh-he-thong',
    dstatcahoadon = 'ds-tat-ca-hoa-don',
    dshoadonchoxuly = 'ds-hoa-don-cho-xu-ly',
    dshoadondaduyet = 'ds-hoa-don-da-duyet',
    dshoadonkhongduyet = 'ds-hoa-don-khong-duyet',
    dshoadondahuy = 'ds-hoa-don-da-huy',
    lochoadon = 'loc-hoa-don',
    dongbotutct = 'dong-bo-tu-tct',
    cauhinhhienthi = 'cau-hinh-hien-thi',
    taixuongpdf = 'tai-xuong-pdf',
    taixuongxml = 'tai-xuong-xml',
    nhandan = 'nhan-dan',
    nhaphoadon = 'nhap-hoa-don', //import
    nhaplieuhoadon = 'nhap-lieu-hoa-don',
    chapnhanduyethoadon = 'chap-nhan-duyet-hoa-don',
    duyethangloat = 'duyet-hang-loat',
    khongduyethoadon = 'khong-duyet-hoa-don',
    huyhoadon = 'huy-hoa-don',
    tthoadon = 'tt-hoa-don',
    lshoatdong = 'ls-hoat-dong',
    inhoadon = 'in-hoa-don',
    dsncc = 'ds-ncc',
    taoncc = 'tao-ncc',
    suancc = 'sua-ncc',
    xoancc = 'xoa-ncc',
    nhapncc = 'nhap-ncc',
    xuatncc = 'xuat-ncc',
    dshanghoa = 'ds-hang-hoa',
    taohanghoa = 'tao-hang-hoa',
    suahanghoa = 'sua-hang-hoa',
    xoahanghoa = 'xoa-hang-hoa',
    nhaphanghoa = 'nhap-hang-hoa',
    xuathanghoa = 'xuat-hang-hoa',
    dsvaitro = 'ds-vai-tro',
    taovaitro = 'tao-vai-tro',
    suavaitro = 'sua-vai-tro',
    xoavaitro = 'xoa-vai-tro',
    dsmauemail = 'ds-mau-email',
    taomauemail = 'tao-mau-email',
    suamauemail = 'sua-mau-email',
    xoamauemail = 'xoa-mau-email',
    battatmauemail = 'bat-tat-mau-email',
    dslsguiemail = 'ds-ls-gui-email',
    ctlsguiemail = 'ct-ls-gui-email',
    guilaiemailthatbai = 'gui-lai-email-that-bai',
    huongdansudung = 'huong-dan-su-dung',
    yeucauhotro = 'yeu-cau-ho-tro',
}
