export enum notification {
    GET_FAIL = 'Lấy danh sách thất bại!',
    CREATE_SUCCESS = 'Tạo mới thành công!',
    CREATE_FAIL = 'Tạo mới thất bại!',
    UPDATE_SUCCESS = 'Cập nhật thành công!',
    UPDATE_FAIL = 'Cập nhật thất bại!',
    UPDATE_PASS_SUCCESS = 'Đặt lại mật khẩu thành công!',
    UPDATE_PASS_FAIL = 'Đặt lại mật khẩu thất bại!',
    DELETE_SUCCESS = 'Xóa thành công!',
    LOCK_SUCCESS = 'Khóa thành công!',
    UNLOCK_SUCCESS = 'Mở khóa thành công!',
    DELETE_FAIL = 'Xóa thất bại!',
    RESEND_SUCCESS = 'Gửi lại thành công!',
    RESEND_FAIL = 'Gửi lại thất bại!',
    SUCCESS = 'Thành công!',
    FAIL = 'Thất bại!',
    MESSAGE_ERROR = 'Có lỗi xảy ra!',
    MESSAGE_ERROR_LOAD = 'Có lỗi xảy ra, bạn cần tải lại trang web!',
    MESSAGE_VALIDATE = 'Kiểm tra lại dữ liệu nhập!',
    SEND_EMAIL_SUCCESS = 'Gửi email thành công!',
    SEND_EMAIL_FAIL = 'Gửi email thất bại!',
    CHECK_SUCCESS = 'Kiểm tra thành công!',
    CHECK_FAIL = 'Kiểm tra thất bại!',
    NOTIFY_READ = 'Đọc thông báo!',
    NOTIFY_DELETE = 'Gỡ thông báo!',
    FILE_DOES_NOT_EXIST = 'File không tồn tại!',
    FILE_SIZE_EXCEED = 'File vượt quá 10MB!',
    FILE_AMOUNT_EXCEED = 'Số lượng file đính kèm tối đa là 10',
    FILE_UPLOAD_CANCEL = 'Hóa đơn tải lên chưa được lưu thông tin. Bạn có chắc chắn muốn hủy?',
    SAVE_COLUMN = 'Lưu cài đặt cột thành công',
    SET_DEFAULT_COMPANY = 'Bạn có chắc chắn muốn đặt mặc định công ty/đơn vị quản lý này sau mỗi lần đăng nhập?',
    CANNOT_DEL_COMPANY = 'Không thể xóa công ty mặc định!',
    DEL_COMPANY = 'Bạn có chắc chắn muốn xóa công ty?',
    DEL_EMAIL_TEMP = 'Bạn có chắc chắn muốn xóa mẫu email?',
    EDIT_EMAIL_TEMP_SUCCESS = 'Sửa mẫu email thành công',
    ADD_EMAIL_TEMP_SUCCESS = 'Thêm mới mẫu email thành công',
    DEL_USER = 'Bạn có chắc chắn muốn khóa người dùng?',
    UNLOCK_USER = 'Bạn có chắc chắn muốn mở khóa người dùng?',
    EDIT_ROLE = 'Sửa vai trò thành công',
    ADD_ROLE = 'Thêm mới vai trò thành công',
    DELETE_ROLE = 'Bạn có chắc chắn muốn xóa vai trò?',
    EDIT_USER = 'Sửa người dùng thành công',
    ADD_USER = 'Thêm mới người dùng thành công',
    UPLOAD_INVOICE_SUCCESS = 'Tải lên hóa đơn thành công',
    UPLOAD_INVOICE_FAIL = 'Tải lên hóa đơn thất bại',
    CREATE_LABEL_FAIL = 'Nhãn dán đã tồn tại',
    UPLOAD_ATTACH_FILE_SUCCESS = 'Tải lên file đính kèm thành công',
    CONFIRM_DELETE_ATTACH_FILE = 'Bạn có chắc chắn muốn xóa file đính kèm này?',
    CONFIRM_DELETE_PRODUCT_FILE = 'Bạn có chắc chắn muốn xóa file hàng hóa/dịch vụ này?',
    DELETE_ATTACH_FILE_SUCCESS = 'Xóa file đính kèm thành công',
    DELETE_PRODUCT_FILE_SUCCESS = 'Xóa file hàng hóa/dịch vụ thành công',
    UPLOAD_ATTACH_FILE_FAIL = 'Tải lên file đính kèm thất bại',
    UPLOAD_TABLEINPUT_CHECK = 'Dữ liệu hàng hóa không hợp lệ',
    CREATE_INVOICE_SUCCESS = 'Tạo hóa đơn thành công',
    EDIT_INVOICE_SUCCESS = 'Sửa hóa đơn thành công',
    CREATE_INVOICE_FAIL = 'Tạo hóa đơn thất bại',
    APPROVAL_INVOICE_CONFIRM = 'Bạn có chắc chắn muốn duyệt hóa đơn này?',
    CANCEL_INVOICE_CONFIRM = 'Bạn có chắc chắn muốn hủy hóa đơn này?',
    DELETE_INVOICE_CONFIRM = 'Bạn có chắc chắn muốn xóa hóa đơn này?',
    CONNECT_TCT_SUCCESS = 'Kết nối đến TCT thành công',
    SYNC_TCT_SUCCESS = 'Đồng bộ từ TCT thành công',
    SYNC_TCT_EMPTYDAY = 'Thời gian đồng bộ không được bỏ trống',
    APPROVAL_INVOICE_SUCCESS = 'Phê duyệt hóa đơn thành công',
    MST_SEARCH_NODATA = 'Không tìm thấy thông tin',
    SEND_EMAIL_NCC_SUCCESS = 'Gửi email thành công',
    LABEL_INVOICE_SUCCESS = 'Dán nhãn hóa đơn thành công',
    CANCEL_APPROVAL_SUCCESS = 'Không duyệt hóa đơn thành công',
    ADD_SUPPLIER_SUCCESS = 'Thêm nhà cung cấp thành công',
    UPDATE_SUPPLIER_SUCCESS = 'Cập nhật nhà cung cấp thành công',
    DELETE_SUPPLIER_CONFIRM = 'Bạn có chắc chắn muốn xóa nhà cung cấp này?',
    DELETE_SUPPLIER_SUCCESS = 'Xóa nhà cung cấp thành công',
    UPLOAD_TABLEACCOUNT_CHECK = 'Thông tin tài khoản không hợp lệ',
    UPLOAD_TABLECONTACT_CHECK = 'Thông tin liên hệ không hợp lệ',
    IMPORT_SUPPLIER_SUCCESS = 'Import nhà cung cấp thành công',
    IMPORT_PRODUCT_SUCCESS = 'Import hàng hóa thành công',
    IMPORT_SUPPLIER_FAIL = 'Import nhà cung cấp thất bại',
    IMPORT_PRODUCT_FAIL = 'Import hàng hóa thất bại',
    EXPORT_EXCEL_NOTI = 'File xuất sẽ hoàn tất trong vài phút nữa. Bạn có thể tiếp tục thao tác đến khi File xuất hoàn thành!',
    DELETE_PRODUCT = 'Bạn có chắc chắn muốn xóa hàng hóa?',
    APPROVAL_ONLY_WAITING = 'Chỉ duyệt các hóa đơn trạng thài Chờ duyệt',
    MAX_LENGTH_ROW = 'Vượt quá số hàng cho phép',
    MIN_LENGTH_ROW = 'Vui lòng thêm ít nhất một hàng',
    TOP_NCC_INVOICE = 'Top 5 NCC có nhiều hóa đơn nhất',
    TOP_NCC_INVOICE_FULL = 'Top 5 nhà cung cấp có nhiều hóa đơn nhất',
    DELETE_EMAIL_CONFIRM = 'Bạn có chắc chắn muốn xóa email này?',
    DELETE_EMAIL_SUCCESS = 'Xóa email thành công',
    DIS_TCT = 'Bạn chắc chắn muốn ngắt kết nối với Tổng cục thuế?',
    RATE_NCC_SUCCESS = 'Đánh giá nhà cung cấp thành công',
    DELETE_NOTIFICATION_CONFIRM = 'Bạn có chắc muốn gỡ thông báo này?',
}
