import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    informationServices: null,
    identificationInfo: null,
    identificationInfoOld: null,
    checkUploadIdentification: null,
    checkUploadLegalPaper: null,
    checkCreateInfo: null,
    legalPaperData: null,
    appointmentPaperData: null,
    editCtsData: null,
    ctsDetail: null,
    dk01: null,
    checkCreateOder: false,
    nfcData: null,
};

export const certManagementSlice = createSlice({
    name: 'certManagement',
    initialState,
    reducers: {
        pushInformationServices: (state, action) => {
            state.informationServices = {
                ...state.informationServices,
                ...action.payload,
            };
        },
        pushIdentificationInfo: (state, action) => {
            state.identificationInfo = {
                ...state.identificationInfo,
                ...action.payload,
            };
        },
        pushIdentificationInfoOld: (state, action) => {
            state.identificationInfoOld = {
                ...state.identificationInfoOld,
                ...action.payload,
            };
        },
        pushCheckUploadIdentification: (state, action) => {
            state.checkUploadIdentification = {
                ...state.checkUploadIdentification,
                ...action.payload,
            };
        },
        pushCheckUploadLegalPaper: (state, action) => {
            state.checkUploadLegalPaper = {
                ...state.checkUploadLegalPaper,
                ...action.payload,
            };
        },
        pushCheckCreateInfo: (state, action) => {
            state.checkCreateInfo = {
                ...state.checkCreateInfo,
                ...action.payload,
            };
        },
        pushLegalPaperData: (state, action) => {
            state.legalPaperData = action.payload;
        },
        pushAppointmentPaperData: (state, action) => {
            state.appointmentPaperData = action.payload;
        },
        pushEditCtsData: (state, action) => {
            state.editCtsData = action.payload;
        },
        pushCtsDetail: (state, action) => {
            state.ctsDetail = {
                ...state.ctsDetail,
                ...action.payload,
            };
        },
        clearRegisterService: (state, action) => {
            return { ...initialState };
        },
        clearUploadLegalPaper: (state, action) => {
            state.checkUploadLegalPaper = null
        },
        pushDk01: (state, action) => {
            state.dk01 = action.payload;
        },
        pushCertManagement: (state, action) => {
            return { ...state, ...action.payload };
        },
        pushCheckCreateOder: (state, action) => {
            state.checkCreateOder = action.payload;
        },
        pushNfcData: (state, action) => {
            state.nfcData = action.payload;
        },
    },
});

const { reducer, actions } = certManagementSlice;
// Action creators are generated for each case reducer function
export const {
    pushInformationServices,
    pushIdentificationInfo,
    pushCheckUploadIdentification,
    pushCheckCreateInfo,
    pushCheckUploadLegalPaper,
    clearUploadLegalPaper,
    pushLegalPaperData,
    pushAppointmentPaperData,
    pushEditCtsData,
    pushCtsDetail,
    clearRegisterService,
    pushDk01,
    pushCertManagement,
    pushIdentificationInfoOld,
    pushCheckCreateOder,
    pushNfcData
} = actions;

export const selectInformationServices = (state) => state?.features?.certManagement?.informationServices;
export const selectIdentificationInfo = (state) => state?.features?.certManagement?.identificationInfo;
export const selectIdentificationInfoOld = (state) => state?.features?.certManagement?.identificationInfoOld;
export const selectCheckUploadIdentification = (state) => state?.features?.certManagement?.checkUploadIdentification;
export const selectCheckUploadLegalPaper = (state) => state?.features?.certManagement?.checkUploadLegalPaper;
export const selectCheckCreateInfo = (state) => state?.features?.certManagement?.checkCreateInfo;
export const selectLegalPaperData = (state) => state?.features?.certManagement?.legalPaperData;
export const selectAppointmentPaperData = (state) => state?.features?.certManagement?.appointmentPaperData;
export const selectEditCtsData = (state) => state?.features?.certManagement?.editCtsData;
export const selectCtsDetail = (state) => state?.features?.certManagement?.ctsDetail;
export const selectDk01 = (state) => state?.features?.certManagement?.dk01;
export const selectCertManagement = (state) => state?.features?.certManagement;
export const selectCheckCreateOder = (state) => state?.features?.certManagement?.checkCreateOder;
export const selectNfcData = (state) => state?.features?.certManagement?.nfcData;

export default reducer;
