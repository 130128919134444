import {
  Circle
} from "react-feather";
import { FcComboChart, FcDataConfiguration, FcDocument, FcExport, FcList, FcRules, FcSurvey } from "react-icons/fc";
/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
  {
    id: "home",
    title: "Thống kê",
    type: "item",
    icon: <FcComboChart size={20} />,
    navLink: "/home",
  },
  {
    id: "invoice_sample",
    title: "Hóa đơn mẫu",
    type: "item",
    icon: <FcRules size={20} />,
    // badge: "warning",
    // badgeText: "2",
    navLink: "/1",
  },
  {
    id: "invoice",
    title: "Hóa đơn đầu vào",
    type: "item",
    icon: <FcDocument size={20} />,
    badge: "warning",
    badgeText: "2",
    children: [
      {
        id: "invoice_sell",
        title: "Hóa đơn bán",
        type: "item",
        icon: <Circle size={12} />,
        // permissions: ["admin"],
        navLink: "/invoices-sell",
      },
      {
        id: "invoice_buy",
        title: "Hóa đơn mua",
        type: "item",
        icon: <Circle size={12} />,
        // permissions: ["admin"],
        navLink: "/hsm",
      },
    ]
  },
  {
    id: "invoice_process",
    title: "Hộp thư",
    type: "item",
    icon: <FcExport size={20} />,
    navLink: "/2",
  },
  {
    id: "category",
    title: "Danh mục",
    type: "item",
    icon: <FcList size={20} />,
    navLink: "/3",
  },
  {
    id: "report",
    title: "Công ty - Chi nhánh",
    type: "item",
    icon: <FcSurvey size={20} />,
    navLink: "/4",
  },
  {
    id: "system_config",
    title: "Cấu hình hệ thống",
    type: "item",
    icon: <FcDataConfiguration size={20} />,
    navLink: "/5",
  },
]
