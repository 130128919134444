// ** React Imports
import { Suspense, useEffect } from 'react';

// ** Utils
import { useLayout } from '@hooks/useLayout';
import { useRouterTransition } from '@hooks/useRouterTransition';

// ** Custom Components
// import Spinner from '@components/spinner/Loading-spinner' // Uncomment if your require content fallback
import LayoutWrapper from '@layouts/components/layout-wrapper';

// ** Router Components
import { Redirect, Route, Switch } from 'react-router-dom';

// ** Routes & Default Routes
import { DefaultRoute, Routes } from './routes';

// ** Layouts
import { SpinnerNoPromise } from '@core/components/spinner/Fallback-spinner';
import BlankLayout from '@layouts/BlankLayout';
import HorizontalLayout from '@src/layouts/HorizontalLayout';
import VerticalLayout from '@src/layouts/VerticalLayout';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ConfigProvider, theme } from 'antd';
import viVNIntl from 'antd/locale/vi_VN';
import companyApi from 'api/company/companyApi';
import { showAlert } from 'api/showAlert';
import userApi from 'api/user/userApi';
import { notification } from 'constants/notification';
import { routerMain } from 'constants/routerMain';
import queryString from 'query-string';
import ReactGA from 'react-ga4';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setAccountData, setRoleData } from 'redux-toolkit/slice/authSlice';
import ComponentSpinner from '../components/@vuexy/spinner/Loading-spinner';
import { socket } from '../socket';
import Error from './../views/Error';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';

// const socket = io('localhost:3001');

const Router = () => {
    // ** Hooks
    const skin = useSelector((state) => state.layout.skin);
    const [layout, setLayout] = useLayout();
    const [transition, setTransition] = useRouterTransition();

    // ** ACL Ability Context
    const dispatch = useDispatch();
    const auth = useAuth();
    const history = useHistory();

    // const permissionArr = useSelector(state => state.authHddv?.roleData)
    const userIdentify = useSelector((state) => state.authHddv?.accountData?.hddvNguoiDungReponse?.maNguoiDung);
    const handleAddLocalVariables = (data) => {
        data.hddvNguoiDung?.maNguoiDung && localStorage.setItem('maNguoiDung', data.hddvNguoiDung?.maNguoiDung);
        data.hddvDoanhNghiep?.maDoanhNghiep &&
            localStorage.setItem('maDoanhNghiep', data.hddvDoanhNghiep?.maDoanhNghiep);
    };

    const handleModalContractSign = () => {
        // handleConfirmCustomBtn(() => localStorage.setItem('contractSigning', 0), handleModalContractSign, "", "Bạn đã đăng ký gói cho doanh nghiệp. Vui lòng ký hợp đồng để sử dụng dịch vụ", 'info', "Ký hợp đồng", "Hủy")
        // logOut(auth)
    };

    const fetchDataUser = async () => {
        try {
            const result = await userApi.getUserDetail();
            const { data, message, status } = result;
            if (status === 200) {
                if (!data.hddvNguoiDungReponse) {
                    history.push(routerMain.UNREGISTER);
                }
                if (
                    data?.hddvDoanhNghiepReponse?.annualFees !== null &&
                    data?.hddvDoanhNghiepReponse?.annualFees === false
                ) {
                    history.push(routerMain.ANNUAL_FEES);
                }

                const permissionArr = data?.hddvQuyenResponses?.map((e) => e.maQuyen);
                let newData = { ...data };
                newData.hddvNguoiDung = newData.hddvNguoiDungReponse;
                newData.hddvDoanhNghiep = newData.hddvDoanhNghiepReponse;
                newData.hddvVaiTro = newData.hddvVaiTroResponses;
                newData.hddvQuyen = newData.hddvQuyenResponses;
                const actionUser = setAccountData(newData);
                const actionRole = setRoleData(permissionArr);
                dispatch(actionUser);
                dispatch(actionRole);
                // checkContractSigning(data.hddvDoanhNghiep?.mstDoanhNghiep)
                // if (!localStorage.getItem('maNguoiDung')) {
                //   const resultSignCheck = checkContractSigning(data.hddvDoanhNghiep?.mstDoanhNghiep)
                //   if (!resultSignCheck?.data) {
                //     localStorage.setItem('contractSigning', 1)
                //     handleModalContractSign()
                //   }
                //   logTime(data.hddvNguoiDung?.maNguoiDung, data.hddvDoanhNghiep?.maDoanhNghiep)
                //   detectUser(data.hddvNguoiDung)
                // }
                handleAddLocalVariables(newData);
            } else {
                history.push(routerMain.UNREGISTER);
            }
        } catch (err) {
            console.error('err: ', err);
            showAlert('error', notification.FAIL, notification.MESSAGE_ERROR);
        }
    };

    useEffect(() => {
        if (auth.isAuthenticated) {
            trackPromise(fetchDataUser());
        }
    }, [auth.isAuthenticated]);

    const location = useLocation();
    const searchQuery = queryString.parse(location.search);

    const fetchSwitchIframe = async () => {
        try {
            const result = await companyApi.switchDefaultIframe(searchQuery?.mst);
            const { message, status } = result;
            if (status === 200) {
                trackPromise(fetchDataUser());
            } else {
                showAlert('error', notification.FAIL, message);
            }
        } catch (error) {
            console.error('error: ', error);
        }
    };

    useEffect(() => {
        if (searchQuery?.mst) {
            trackPromise(fetchSwitchIframe());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery?.mst]);

    useEffect(() => {
        socket.on('connect', () => {
            userIdentify && socket.emit('userConnected', userIdentify);
        });
        userIdentify && socket.emit('userConnected', userIdentify);
        socket.on('return_TCT', function (e) {
            showAlert('success', notification.SUCCESS, e);
        });
    }, [userIdentify]);

    useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    useEffect(() => {
        return auth.events.addUserSignedOut(() => auth.signinRedirect());
    }, [auth.events, auth.signinRedirect]);

    useEffect(() => {
        return auth.events.addUserSignedIn(() => auth.signinRedirect());
    }, [auth.events, auth.signinRedirect]);

    useEffect(() => {
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addAccessTokenExpiring(() => {
            if (alert("You're about to be signed out due to inactivity. Press continue to stay signed in.")) {
                auth.signinSilent();
            }
        });
    }, [auth.events, auth.signinSilent]);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        const checkSignContract = localStorage.getItem('contractSigning');
        if (checkSignContract && checkSignContract === '1') handleModalContractSign();
    }, []);

    if (!auth) {
        return <div>Loading...</div>;
    }

    // ** Default Layout
    const DefaultLayout = layout === 'horizontal' ? 'HorizontalLayout' : 'VerticalLayout';

    // ** All of the available layouts
    const Layouts = { BlankLayout, VerticalLayout, HorizontalLayout };

    // ** Current Active Item
    const currentActiveItem = null;

    // ** Return Filtered Array of Routes & Paths
    const LayoutRoutesAndPaths = (layout) => {
        const LayoutRoutes = [];
        const LayoutPaths = [];

        if (Routes) {
            Routes.filter((route) => {
                // ** Checks if Route layout or Default layout matches current layout
                if (route.layout === layout || (route.layout === undefined && DefaultLayout === layout)) {
                    LayoutRoutes.push(route);
                    LayoutPaths.push(route.path);
                }
            });
        }

        return { LayoutRoutes, LayoutPaths };
    };

    // ** Init Error Component
    // const Error404 = lazy(() => import("@src/views/Error404"));
    /**import { useSkin } from '@hooks/useSkin';
import { socket } from '../socket';
import { viVNIntl } from 'antd/lib/locale/vi_VN';

   ** Final Route Component Checks for Login & User Role and then redirects to the route
   */
    const FinalRoute = (props) => {
        const route = props.route;
        let action, resource;

        // ** Assign vars based on route meta
        if (route.meta) {
            action = route.meta.action ? route.meta.action : null;
            resource = route.meta.resource ? route.meta.resource : null;
        }

        if (
            (!auth?.isAuthenticated && route.meta === undefined) ||
            (!auth?.isAuthenticated && route.meta && !route.meta.authRoute && !route.meta.publicRoute)
        ) {
            /**
             ** If user is not Logged in & route meta is undefined
             ** OR
             ** If user is not Logged in & route.meta.authRoute, !route.meta.publicRoute are undefined
             ** Then redirect user to login
             */
            // return auth.signinRedirect();
            // return <Redirect to="/" />;
        } else if (route.meta && route.meta.authRoute) {
            // ** If route has meta and authRole and user is Logged in then redirect user to home page (DefaultRoute)
            return <Redirect to={routerMain.HOME} />;
        } else {
            // ** If none of the above render component
            return <route.component {...props} />;
        }
    };

    // ** Return Route to Render
    const ResolveRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            // ** Convert Layout parameter to Layout Component
            // ? Note: make sure to keep layout and component name equal

            const LayoutTag = Layouts[layout];

            // ** Get Routes and Paths of the Layout
            const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout);

            // ** We have freedom to display different layout for different route
            // ** We have made LayoutTag dynamic based on layout, we can also replace it with the only layout component,
            // ** that we want to implement like VerticalLayout or HorizontalLayout
            // ** We segregated all the routes based on the layouts and Resolved all those routes inside layouts

            // ** RouterProps to pass them to Layouts
            const routerProps = {};

            return (
                <Route path={LayoutPaths} key={index}>
                    <LayoutTag
                        routerProps={routerProps}
                        layout={layout}
                        setLayout={setLayout}
                        transition={transition}
                        setTransition={setTransition}
                        currentActiveItem={currentActiveItem}
                    >
                        <Switch>
                            {LayoutRoutes.map((route) => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={true}
                                        render={(props) => {
                                            // ** Assign props to routerProps
                                            Object.assign(routerProps, {
                                                ...props,
                                                meta: route.meta,
                                            });

                                            return (
                                                <Suspense fallback={<ComponentSpinner />}>
                                                    {/* Layout Wrapper to add classes based on route's layout, appLayout and className */}
                                                    <LayoutWrapper
                                                        layout={DefaultLayout}
                                                        transition={transition}
                                                        setTransition={setTransition}
                                                        /* Conditional props */
                                                        /*eslint-disable */
                                                        {...(route.appLayout
                                                            ? {
                                                                  appLayout: route.appLayout,
                                                              }
                                                            : {})}
                                                        {...(route.meta
                                                            ? {
                                                                  routeMeta: route.meta,
                                                              }
                                                            : {})}
                                                        {...(route.className
                                                            ? {
                                                                  wrapperClass: route.className,
                                                              }
                                                            : {})}
                                                        /*eslint-enable */
                                                    >
                                                        {/* <route.component {...props} /> */}
                                                        <FinalRoute route={route} {...props} />
                                                    </LayoutWrapper>
                                                </Suspense>
                                            );
                                        }}
                                    />
                                );
                            })}
                            <Route path="*" component={Error} />
                        </Switch>
                    </LayoutTag>
                </Route>
            );
        });
    };

    if (auth.activeNavigator === 'signinSilent') {
        return <div>Signing you in...</div>;
    }
    if (auth.activeNavigator === 'signoutRedirect') {
        return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <SpinnerNoPromise />;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        return (
            <ConfigProvider
                locale={viVNIntl}
                theme={{
                    algorithm: skin === 'dark' ? theme.darkAlgorithm : theme.lightAlgorithm,
                    token: {
                        colorPrimary: '#9f1d21',
                    },
                }}
            >
                <Switch>
                    {/* If user is logged in Redirect user to DefaultRoute else to login */}
                    <Route
                        exact={false}
                        path="/homepage"
                        render={() => {
                            return <Redirect to={routerMain.HOME} />;
                        }}
                    />
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return auth?.isAuthenticated ? <Redirect to={DefaultRoute} /> : auth.signinRedirect();
                            // (
                            //   <Redirect to="/login" />
                            // );
                        }}
                    />
                    {ResolveRoutes()}
                    {/* NotFound Error page */}
                    <Route path="*" component={Error} />
                </Switch>
            </ConfigProvider>
        );
    }

    return auth.signinRedirect();
};

export default Router;
