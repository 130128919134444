export enum routerMain {
    HOME = '/home',
    UNREGISTER = '/unregistered',
    CONFIG = '/config',
    ERROR = '/error',

    PAGE_403 = '/403',
    PAGE_500 = '/500',
    PAGE_404 = '/404',

    NONE = '/',

    LIST_INVOICE = '/supplier-invoice/list',
    CREATE_INVOICE = '/supplier-invoice/add',
    LIST_COMPANY = '/company-list',
    CONNECT_TCT = '/connect-tct',
    USER_LIST = '/user-list',
    ROLE = '/config/role',
    SUPPLIER = '/category/supplier',
    PRODUCT = '/category/product',
    CONFIG_SYSTEM = '/config/system',
    CONFIG_EMAIL = '/config/email-template',
    ADD_INVOICE = '/invoices-add',
    SUPPLIER_INVOICE_ADD = '/supplier-invoice/add',
    ADD_INPUT = '/supplier-invoice/add/input',
    ADD_INPUT_UUID = '/supplier-invoice/add/input/:uuid',
    ADD_INPUT_EDIT_UUID = '/supplier-invoice/add/edit/:uuid',
    ADD_INPUT_VIEW_UUID = '/supplier-invoice/view/input/:uuid',
    ACCOUNT = '/account',
    LIST_PACKAGE = '/package',
    CONTRACT = '/contract',
    MAILBOX = '/mailbox',
    ANNUAL_FEES = '/annual-fees',
    INSTRUCTIONS_FOR_USE = '/instructions-for-use',
    REQUEST_SUPPORT = '/request-support',
    NOTIFICATIONS = '/notifications',
}
