import axiosClient from '../axiosClient';
const PREFIX_URL = "api/hddv/doanhnghiep";

const companyApi = {
    getList: (params) => {
        const url = `${PREFIX_URL}/list`;
        return axiosClient.get(url, { params });
    },
    getListCompanyShare: (params) => {
        const url = `${PREFIX_URL}/list-enterprise-share`;
        return axiosClient.get(url, { params });
    },
    getDetail: (param) => {
        const url = `${PREFIX_URL}/detail/${param}`;
        return axiosClient.get(url, {});
    },
    addCompany: (data) => {
        const url = `${PREFIX_URL}/add`;
        return axiosClient.post(url, data);
    },
    editCompany: (data) => {
        const url = `${PREFIX_URL}/edit`;
        return axiosClient.post(url, data);
    },
    deleteCompany: (param) => {
        const url = `${PREFIX_URL}/delete/${param}`;
        return axiosClient.post(url, {});
    },
    getTree: (params) => {
        const url = `${PREFIX_URL}/get-tree-by-user`;
        return axiosClient.get(url, { params });
    },
    setDefaultCompany: (param) => {
        const url = `${PREFIX_URL}/switch-default/${param}`;
        return axiosClient.post(url, {});
    },
    switchDefaultIframe: (param) => {
        const url = `${PREFIX_URL}/switch-default-iframe/${param}`;
        return axiosClient.post(url, {});
    },
}

export default companyApi;
