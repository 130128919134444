import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { createPortal } from 'react-dom';
import { useContext, useEffect } from 'react';
import { NotificationsContext } from './NotificationContext';
import useDeleteNotificationByIdApi from 'hooks/useDeleteNotificationByIdApi';
import { useQueryClient } from '@tanstack/react-query';
import { CheckOutlined, DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';

const OptionsNotification = ({ item }) => {
    const { handleClickOptionItem } = useContext(NotificationsContext);
    const deleteNotificationById = useDeleteNotificationByIdApi();
    const queryClient = useQueryClient();
    const handleDeleteNotificationById = (item) => {
        deleteNotificationById.mutate(item.maThongBao, {
            onSuccess: () => {
                queryClient.invalidateQueries('notifications');
            },
        });
    };
    const options = [
        {
            key: 1,
            title: item.daDoc ? 'Đánh dấu chưa đọc' : 'Đánh dấu đã đọc',
            // icon: <Check id="iconOptionIsRead" />,
            icon: <CheckOutlined />,
            onClick: () => {
                handleClickOptionItem(item, Number(!item.daDoc));
            },
        },
        {
            key: 2,
            title: 'Gỡ thông báo này',
            icon: <DeleteOutlined />,
            onClick: () => {
                handleDeleteNotificationById(item);
            },
        },
    ];
    useEffect(() => {
        const updatePosition = () => {
            const parent = document.querySelector('#optionsNotifications');
            const child = document.querySelector('#dropdownMenuOptions');
            const parentRect = parent.getBoundingClientRect();
            child.style.top = `${parentRect.top + parentRect.height}px}`;
            child.style.left = `${parentRect.left}px`;
        };
        updatePosition();
        window.addEventListener('resize', updatePosition);
        return () => {
            window.removeEventListener('resize', updatePosition);
        };
    }, []);
    return (
        <>
            <UncontrolledDropdown
                direction="down"
                tag="div"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                className="options-notifications"
                id="optionsNotifications"
            >
                <>
                    <DropdownToggle tag="div">
                        <Button.Ripple className="btn-icon rounded-circle" color="secondary" outline>
                            {/* <MoreHorizontal size={16} /> */}
                            <EllipsisOutlined />
                        </Button.Ripple>
                    </DropdownToggle>
                    {createPortal(
                        <DropdownMenu tag="ul" id="dropdownMenuOptions" className="dropdownMenuOptions">
                            {options.map((option) => (
                                <li key={option.key}>
                                    <DropdownItem className="w--100" onClick={option.onClick}>
                                        {option.icon} {option.title}
                                    </DropdownItem>
                                </li>
                            ))}
                        </DropdownMenu>,
                        document.body
                    )}
                </>
            </UncontrolledDropdown>
        </>
    );
};

export default OptionsNotification;
