import classNames from 'classnames';
import { useContext, useState } from 'react';
import { Bell } from 'react-feather';
import OptionsNotification from './OptionsNotification';
import { Media } from 'reactstrap';
import { NotificationsContext } from './NotificationContext';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton } from 'antd';
const NotificationItems = () => {
    const { handleClickOptionItem, listNotifications, fetchMoreData, notificationProcessing, getListNotifications } =
        useContext(NotificationsContext);
    const [mouseEnter, setMouseEnter] = useState(null);
    const handleOnMouseEnter = (item) => {
        setMouseEnter(item);
    };
    const handleOnMouseLeave = () => setMouseEnter(null);

    return (
        <>
            <InfiniteScroll
                dataLength={listNotifications.length}
                next={fetchMoreData}
                hasMore={
                    !notificationProcessing.isLoading &&
                    listNotifications.length < getListNotifications?.data?.totalData
                }
                loader={
                    <>
                        <Media className="d-flex justify-content-center align-items-center">
                            <Skeleton.Avatar active style={{ height: '56px', width: '56px' }}></Skeleton.Avatar>
                            <Skeleton.Input className="ml-50" size="small" active block />
                        </Media>
                        <Media className="d-flex justify-content-center align-items-center">
                            <Skeleton.Avatar active style={{ height: '56px', width: '56px' }}></Skeleton.Avatar>
                            <Skeleton.Input className="ml-50" size="small" active block />
                        </Media>
                    </>
                }
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Không còn thông báo nào!</b>
                    </p>
                }
                scrollableTarget="parentScrollDiv"
                scrollThreshold={0.8}
            >
                <li>
                    <div className="d-flex justify-content-between ml-1 mr-1 mt-50">
                        <h4 className="mr-auto">Trước đó</h4>
                        <Link to="/notifications"> Xem tất cả</Link>
                    </div>
                </li>
                {listNotifications.length > 0 ? (
                    listNotifications.map((item) => {
                        return (
                            <Link
                                key={item.maThongBao}
                                //Nếu thông báo đã đọc => text màu secondary
                                className={classNames('notification-item', {
                                    'text-secondary': item.daDoc,
                                })}
                                to={item.link}
                                onClick={() => handleClickOptionItem(item, 1)}
                                onMouseEnter={() => handleOnMouseEnter(item)}
                                onMouseLeave={handleOnMouseLeave}
                            >
                                {mouseEnter === item && <OptionsNotification item={item} />}
                                <Media key={item.maThongBao}>
                                    <Media left>
                                        <div className="notification-item--avt">
                                            <div className="bg-light notification-item--bell ">
                                                <Bell size={20} />
                                            </div>
                                        </div>
                                    </Media>
                                    <Media body>
                                        <div>{item.tieuDe}</div>
                                        <div>
                                            <small
                                                //nếu thông báo chưa đọc thì có màu info
                                                className={classNames('notification-text', {
                                                    'text-info': !item.daDoc,
                                                })}
                                            >
                                                {item.ngayTao}
                                            </small>
                                        </div>
                                    </Media>
                                    {!item.daDoc && (
                                        <div className="notification-item--dot bg-info rounded-circle"></div>
                                    )}
                                </Media>
                            </Link>
                        );
                    })
                ) : (
                    <h2 className="text-center">Không có thông báo mới!</h2>
                )}
            </InfiniteScroll>
        </>
    );
};

export default NotificationItems;
