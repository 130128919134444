// ** Logo
import { Rings, InfinitySpin } from 'react-loader-spinner';
import { usePromiseTracker } from 'react-promise-tracker';

const SpinnerComponent = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        <>
            {promiseInProgress && (
                <SpinnerNoPromise/>
            )}
        </>
    );
};
export const SpinnerNoPromise = () => {
    return (
        <div className="fallback-spinner">
            <div className="loading">
                {/* <Rings color="#9f1d21" height={55} width={55} /> */}
                <InfinitySpin color="#9f1d21" height={55} width={150} />
            </div>
        </div>
    );
};

export default SpinnerComponent;
