import { useQuery } from '@tanstack/react-query';
import notificationsUserApi from 'api/notificationsUserApi';

const useGetListNotifications = (params) => {
    // Đảm bảo hook trả về một giá trị
    return useQuery({
        queryKey: ['notifications', params],
        queryFn: async () => {
            return await notificationsUserApi.getListNotifications(params);
        },
        keepPreviousData: true,
    });
};

export default useGetListNotifications;
