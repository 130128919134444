import React from 'react';
import ReactDOM from 'react-dom';
import '../../../assets/scss/components/app-loader.scss';
import { InfinitySpin } from 'react-loader-spinner';
export default function ComponentSpinner() {
    return ReactDOM.createPortal(
        <div className="fallback-spinner">
            <div className="loading">
                {/* <Rings color="#9f1d21" height={55} width={55} /> */}
                <InfinitySpin color="#9f1d21" height={55} width={150} />
            </div>
        </div>,
        document.body
    );
}
