export const IS_LOADING = "isLoading";
export const ADD_CART = "addCart";
export const DECREASE_CART = "decreaseCart";
export const DEL_CART = "dellCart";
export const ADD_CTS = "addCts";
export const LOGIN = "login";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const SET_COMPANY_DATA = "SET_COMPANY_DATA";
export const LOGOUT = "logout";
export const DEPOSIT = "deposit";
