import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import errorImg from '@src/assets/images/pages/error.svg'

import '@styles/base/pages/page-misc.scss'
import { routerMain } from 'constants/routerMain'

const Error = () => {
  return (
    <div className='misc-wrapper'>
      <div className='misc-inner p-2 p-sm-3'>
        <div className='w-100 text-center'>
          <h1 className='mb-1 text-primary display-1'>404</h1>
          <h2 className='mb-1'>Rất tiếc, trang này không tồn tại 🕵🏻‍♀️</h2>
          <p className='mb-2'>Oops! 😖 Trang đã bị xóa hoặc địa chỉ không đúng!</p>
          <Button.Ripple tag={Link} to={routerMain.HOME} color='primary' className='btn-sm-block mb-2'>
            Quay lại Trang chủ
          </Button.Ripple>
          <img className='img-fluid' src={"https://cdn.dribbble.com/users/46425/screenshots/1799682/shot.gif"} alt='Not found page' />
        </div>
      </div>
    </div>
  )
}
export default Error
