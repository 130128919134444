import React from 'react';
import { toast } from 'react-toastify';
import store from 'redux-toolkit/store';

export const showAlert = (type, title, mess) => {
  console.log('store: ', store);
    const skin = store?.getState()?.layout?.skin;
    console.log('skin: ', skin);

    const Msg = ({ closeToast, toastProps }) => (
        <div>
            <span className="font-weight-bolder">{title}</span>
            <div>{mess}</div>
        </div>
    );

    const theme = skin === 'dark' ? 'dark' : 'colored';
    const defaultOption = {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        theme: theme,
    };

    switch (type) {
        case 'error':
            toast.error(<Msg />, {
                ...defaultOption,
                toastId: 'error',
            });
            break;
        case 'warning':
            toast.warning(<Msg />, {
                ...defaultOption,
                toastId: 'warning',
            });
            break;
        case 'info':
            toast.info(<Msg />, {
                ...defaultOption,
                toastId: 'info',
            });
            break;
        default:
            toast.success(<Msg />, {
                ...defaultOption,
                toastId: 'success',
            });
    }
    // return (
    //   <>
    //     <ToastContainer
    //       position="top-right"
    //       autoClose={500}
    //       hideProgressBar={false}
    //       newestOnTop={true}
    //       closeOnClick
    //       rtl={false}
    //       pauseOnFocusLoss
    //       draggable
    //       pauseOnHover={false}
    //       limit={5}
    //     />
    //   </>
    // );
};
