// import store from 'app/store';
import axios from 'axios';
import queryString from 'query-string';
import Swal from 'sweetalert2';

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 120000,
    headers: {
        'content-type': 'application/json',
        "X-Requested-With": "XMLHttpRequest",
        "Accept-Language": "vi-VN",
        "doanhnghiepma": localStorage.getItem('maDoanhNghiep'),
        "userma": localStorage.getItem('maNguoiDung')
    },
    paramsSerializer: params => queryString.stringify(params),
    // params: {
    //     tenant_id: '1',
    // },
});

axiosClient.interceptors.request.use(async (config) => {
    config.headers.doanhnghiepma = localStorage.getItem('maDoanhNghiep')
    config.headers.userma = localStorage.getItem('maNguoiDung')
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_KC_CLIENT_ID}`))
    const token = oidcStorage ? oidcStorage.access_token : "";
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {

        return response.data;
    }
    return response;
}, (error) => {
    console.error('error: ', error);
    // Handle errors
    const response = error?.response?.data;
    if (response && response.status_code === 401) {
        Swal.fire({
            title: "Error",
            text: response.error.message,
            icon: "error",
            dangerMode: false
        });
        return
    }
    Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        dangerMode: false
    });
    throw error;
});
export default axiosClient;
