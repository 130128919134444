// import rootReducer from "./reducers/rootReducer";
import { configureStore } from '@reduxjs/toolkit'

// We'll use redux-logger just as an example of adding another middleware
import navbar from "./../redux/reducers/navbar";
import layout from "./../redux/reducers/layout";
import myreducers from "./../redux/reducers/myreducers/index";
import authSlice from './slice/authSlice';
import exportExcelSlice from './thunk/exportthunk';
import featuresReducer from './reducers/featuresReducer';

// const middleware = __DEV__ ? [logger] : [];

const store = configureStore({
    reducer: {
        navbar,
        layout,
        // emailApp: emailReducer,
        myreducers,
        authHddv: authSlice,
        b64Export: exportExcelSlice,
        // sign: signSlice,
        features: featuresReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
        ),
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    devTools: true,

})
export default store;
