// ** Router Import
import Router from './router/Router';
// ** React Imports
import { Suspense, useEffect, Fragment } from 'react';

// ** Redux Imports
import { Provider } from 'react-redux';
import store from './redux-toolkit/store';

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import SpinnerComponent from './@core/components/spinner/Fallback-spinner';
import ComponentSpinner from './@core/components/spinner/Loading-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/components/prism-jsx.min';
import 'prismjs/themes/prism-tomorrow.css';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/custom/_custom.scss';
import './assets/scss/style.scss';
import { IntlProviderWrapper } from './utility/context/Internationalization';

// ** Service Worker
import '@fortawesome/fontawesome-free/css/all.min.css';
import ReactGA from 'react-ga4';
import { AuthProvider } from 'react-oidc-context';
import oidcConfig from './configs/oidcConfig';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { BrowserRouter as AppRouter } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID_GA);

const App = (props) => {
    const queryClient = new QueryClient();

    useEffect(() => {
        queryClient.setDefaultOptions({
            queries: {
                //keepPreviousData: true,
                // cacheTime: 0,
                refetchOnWindowFocus: false,
                retry: false,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ErrorComponent = process.env.NODE_ENV === 'production' ? Fragment : ErrorBoundary;

    return (
        <ErrorComponent>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider {...oidcConfig}>
                        <Suspense fallback={<ComponentSpinner />}>
                            <ThemeContext>
                                <IntlProviderWrapper>
                                    <AppRouter>
                                        <Router />
                                    </AppRouter>

                                    <ReactQueryDevtools initialIsOpen />
                                    <ToastContainer newestOnTop />
                                    <SpinnerComponent />
                                </IntlProviderWrapper>
                            </ThemeContext>
                        </Suspense>
                    </AuthProvider>
                </QueryClientProvider>
            </Provider>
        </ErrorComponent>
    );
};

export default App;
